/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "swucareer-20210715104616-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "http://swucareer-20210715104616-hostingbucket-dev.s3-website-ap-northeast-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "ap-northeast-1:6f273a16-17b1-4088-9eb6-3cd298dd0871",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_fS6lUAAZO",
    "aws_user_pools_web_client_id": "5kooedmic6utaouv6judr6d3m9",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://pa6kqjoc5reufjgessrpel7bty.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "swutmpcsvstorage103216-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_cognito_login_mechanisms": [
        "EMAIL",
        "PREFERRED_USERNAME"
    ]
};


export default awsmobile;
